export const CurrentUserMapper = (item) => {
  const copiedItem = { ...item };
  delete copiedItem.id;
  delete copiedItem.username;

  return {
    ...copiedItem,
    _id: item.id,
    userName: item.username,
  };
};
