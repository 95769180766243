import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { onError } from "@apollo/link-error";

import { BACKEND_GRAPHQL_URL } from "./config";
import { getValidToken } from "utils/auth";
import { trackError } from "utils/track";

const httpLink = new createUploadLink({
  uri: BACKEND_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = getValidToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "client-name": "Pronet Admin [web]",
      "client-version": "1.0.0",
    },
    fetch,
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) => {
      let adminLoginError = true;
      if (Array.isArray(path) && path.length > 0) {
        // if not login error
        if (path[0] !== "adminLogin") {
          adminLoginError = false;
        }
      }
      !adminLoginError && trackError(`[GraphQL error]: Message: ${message}, Path: ${path[0]}`, new Error("Network Error"));
      return null;
    });
  }
  if (networkError) {
    console.log(`[Network error] ${networkError}`);
    trackError(`[Network error] ${networkError}`, new Error("Network Error"));
  }
});

const cache = new InMemoryCache();
cache.writeData({
  data: {
    isLoggedIn: !!getValidToken(),
  },
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(errorLink).concat(httpLink),
  resolvers: {},
});

export default client;
