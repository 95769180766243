import { fetcher } from "utils/axios";

import { CurrentUserMapper } from "./current-user-mapper";
import { CurrentEducatorMapper } from "./current-educator-mapper";

export const signIn = async ({ payload }) => {
  const response = await fetcher.post(`/admin/auth/sign-in`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const forgotPassword = async ({ payload }) => {
  const response = await fetcher.post(`/admin/auth/forgot-password`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const resetPassword = async ({ payload }) => {
  const response = await fetcher.post(`/admin/auth/reset-password`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getCurrentUser = async () => {
  const response = await fetcher("/admin/auth/me");

  if (response.status === 200) {
    return CurrentUserMapper(response.data.data);
  }

  throw new Error(response.data.message);
};

export const getCurrentUserPermissions = async () => {
  const response = await fetcher("/admin/auth/my-permissions");

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getCurrentEducator = async ({ queryKey }) => {
  const [, params] = queryKey;
  const response = await fetcher("/admin/auth/my-educator", { params });

  if (response.status === 200) {
    return CurrentEducatorMapper(response.data.data);
  }

  throw new Error(response.data.message);
};

export const loginAsUser = async ({ payload }) => {
  const response = await fetcher.post(`/admin/auth/sign-in/as-user`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getMindhubSso = async () => {
  try {
    const response = await fetcher("/admin/auth/mindhub/autologin");

    if (!response.data.data?.autologinUrl) {
      throw new Error("Your user does not have linked account.");
    }

    return {
      url: response.data.data.autologinUrl,
    };
  } catch (e) {
    if (e.response) {
      throw new Error(e.response.data.message);
    } else {
      throw new Error(e.message);
    }
  }
};
