import gql from "graphql-tag";

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

const GET_ALL_ADMIN_USERS = gql`
  query GetAllAdminUsers {
    getAllAdminUsers {
      _id
      roleId
      role {
        _id
        name
        type
      }
      email
      password
      userName
      fullName
      languageType
      avatarURL
      country
      status
    }
  }
`;

export { IS_LOGGED_IN, GET_ALL_ADMIN_USERS };
