import * as Sentry from "@sentry/browser";

const isDevMode = () => {
  return process.env.NODE_ENV !== "production";
};

class DefaultError extends Error {
  constructor(message, error) {
    super(message);
    this.success = "failed";
    this.originError = error;
  }
}

export const trackError = (message, error) => {
  if (!isDevMode()) {
    Sentry.captureException(new DefaultError(`${message}`, error));
  } else {
    console.log("[TRACK]", message);
  }
};
