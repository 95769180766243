export const EducatorMapper = (item) => {
  const copiedItem = { ...item };
  delete copiedItem.id;
  delete copiedItem.fullName;
  delete copiedItem.languageType;
  delete copiedItem.avatarURL;
  delete copiedItem.isHidden;
  delete copiedItem.subcategories;

  return {
    ...copiedItem,
    _id: item.id,
    name: item.fullName,
    hidden: item.isHidden || false,
    user: {
      _id: item.userId,
      languageType: item.languageType,
    },
    categories: item.subcategories.map((category) => ({
      categoryId: category.id,
      linked: category.linked,
      category: {
        _id: category.id,
      },
    })),
  };
};
