const configGlobal = require("./config-global");

module.exports = {
  API_URL: configGlobal.API_URL || "",
  BACKEND_GRAPHQL_URL: configGlobal.BACKEND_GRAPHQL_URL || "",
  AWS_S3_BUCKET_NAME: configGlobal.AWS_S3_BUCKET_NAME || "",
  AWS_S3_REGION: configGlobal.AWS_S3_REGION || "",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DNS,
  ROWS_ON_PAGE: 8,
  DEBOUNCE_DELAY: 400,
  GOOGLE_TRANSLATE_API_KEY: process.env.REACT_APP_GOOGLE_TRANSLATE_KEY,
  FE_URL: configGlobal.FE_URL,
  FROALA_LICENSE_KEY: configGlobal.FROALA_LICENSE_KEY,
};
